import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.scss';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

(function (r, d, s) {
  r._tt_config = true;
  var _baq = (r._baq = r._baq || []);
  _baq.methods = ['track', 'off', 'on'];
  _baq.factory = function (method) {
    return function () {
      var args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      _baq.push(args);
      return _baq;
    };
  };
  for (var i = 0; i < _baq.methods.length; i++) {
    var key = _baq.methods[i];
    _baq[key] = _baq.factory(key);
  }
  if (_baq.invoked) {
    return;
  }
  _baq.invoked = true;
  _baq.track('pageview');
})(window, document, 'script');
