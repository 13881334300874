import React, { useEffect, useRef, useState } from 'react';
import '../../App.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { commitOrder, getCardPool, getGoodsData, getSMSCode, getRetentionList, getIpAddress } from '../.././api';
import Countdown from '../../components/count_down';
import MCheckbox from '../../components/m_checkbox';
import MCityPicker from '../../components/m_city_picker/m_city_picker';
import CityRetreat from '../../components/m_city_retreat/m_city_retreat';
import ProtocolDialog from '../../components/protocol_dialog';
import RetentionDialog from '../../components/retention_dialog';
import ResellDialog from '../../components/resell_dialog';
import { parseLocationParams, showConfirm, showToast } from '../../utils';
import Slider from 'react-slick';
import dayjs from 'dayjs';
const dataList = require('./data.json');

const CAPTCHA_TIME = 60;
// 倒计时-时分秒
const COUNTDOWN_NUM = 56391;

let scrollBack = {
  show: false,
};
const searchValues = [
  { label: '推荐', value: 'all' },
  { label: 'AAA', value: 'AAA' },
  { label: 'ABAB', value: 'ABAB' },
  { label: 'AABB', value: 'AABB' },
  { label: 'ABCD', value: 'ABCD' },
  { label: '爱情号', value: '521' },
  { label: '不带4', value: 'no4' },
  { label: '尾号8', value: 'end8' },
];
let dataMap = {};
export default function IndexPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [select, setSelect] = useState(0);
  // 页面后缀码
  const [suffixCode, setSuffixCode] = useState('zsy003');
  // 商品列表
  const [goodsList, setGoodsList] = useState([]);
  // 当前商品
  const [currentGoods, setCurrentGoods] = useState();

  // 所有字段都显示
  const [showAll, setShowAll] = useState(false);
  // 是否需要身份证号
  const [needIdCard, setNeedIdCard] = useState(false);
  // 姓名
  const [name, setName] = useState('');
  // 电话
  const [tel, setTel] = useState('');
  // 验证码
  const [captcha, setCaptcha] = useState('');
  // 验证码key
  const [captchaKey, setCaptchaKey] = useState('');
  // 验证码显示时间
  const [captchaTime, setCaptchaTime] = useState(0);
  // 验证码计算时间和timer
  const captchaTimer = useRef({});
  // 倒计时计算时间和timer
  const countdownTimer = useRef({});
  const [countdownTime, setCountdownTime] = useState(0);
  // 身份证号
  const [idCard, setIdCard] = useState('');
  // 地址选择
  const [province, setProvince] = useState();
  const [city, setCity] = useState();
  const [area, setArea] = useState();
  // 详细地址
  const [addressDetail, setAddressDetail] = useState('');
  // 同意协议
  const [checkProtocol, setCheckProtocol] = useState(false);
  // 要显示的协议 user inter
  const [showProtocol, setShowProtocol] = useState('');
  const [zhedieImg, setZhedieImg] = useState(true);
  const [showToTop, setShowToTop] = useState(false);
  // 转卡隐私协议
  const [resellDialogShow, setResellDialogShow] = useState(false);
  // 挽留弹窗控件
  const [retentionDialogShow, setRetentionDialogShow] = useState(false);
  // 挽留数据
  const [retentionData, setRetentionData] = useState([]);
  // 当前挽留数据
  const [showData, setShowData] = useState([]);
  // 是否新增过历史记录
  const [pushHistoryFlag, setPushHistoryFlag] = useState(false);
  //------------------------
  // 步骤 1=>选卡号  2=> 填写信息
  const [step, setStep] = useState('1');
  // 选号类型
  const [searchValue, setSearchValue] = useState('all');
  // 搜索输入框的内容
  const [searchValueInput, setSearchValueInput] = useState('');
  // 号码池
  const [cardPool, setCardPool] = useState({});
  // 号码池分页
  const [cardPoolPage, setCardPoolPage] = useState(0);
  // 已选的号
  const [selectCardNum, setSelectCardNum] = useState();
  //------------------------
  // 归属地选择
  const [retreatProvince, setRetreatProvince] = useState('');
  const [retreatCity, setRetreatCity] = useState('');
  // 默认城市
  const [ipProvince, setipProvince] = useState('');
  const [ipCity, setipCity] = useState('');
  useEffect(() => {
    let params = parseLocationParams(location.search);
    let id = params?.id ?? '7jSwd3Bx6j';
    // let id = params?.id ?? 'zsy010';

    if (id) {
      setSuffixCode(id);
      loadGoodsList(id);
    }
    // 挽留
    getRetentionListFn(id);
    window.addEventListener('scroll', (e) => {
      let show = window.scrollY > 1200;
      if (scrollBack.show !== show) {
        scrollBack.show = show;
        setShowToTop(show);
      }
    });
  }, [location]);

  useEffect(() => {
    getLocation();
  }, []);
  // 获取位置信息
  async function getLocation() {
    try {
      const res = await getIpAddress();
      if (res.code === 200) {
        let data = res.data;
        setipProvince(data.province ? data.province + ' ' : '');
        setipCity(data.city ? data.city : '');
        setRetreatProvince(data.province);
        setRetreatCity(data.city);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (currentGoods) {
      setNeedIdCard(currentGoods.pushType !== 'iot');
      setShowAll(currentGoods.pushType === 'iot');
      if (currentGoods.pushType !== 'iot' && currentGoods.selectedNumStatus === 'Y') {
        loadCardPool(currentGoods.id, '', searchValue);
        setStep('1');
      } else {
        setStep('2');
      }
      document.title = currentGoods.pageTitle || currentGoods.pageName;
    }
  }, [currentGoods]);

  useEffect(() => {
    if (currentGoods?.ifBelongPlace === 'Y') {
      loadCardPool(currentGoods?.id, '', searchValue);
    }
  }, [retreatProvince, retreatCity, currentGoods]);

  // 加载号码池
  async function loadCardPool(goodsId, type, numberKeyword) {
    if (cardPool[currentGoods?.id + numberKeyword + retreatProvince + retreatCity] && type !== 'next') {
      return;
    }
    try {
      let res = await getCardPool({
        id: goodsId,
        numberKeyword,
        province: currentGoods?.ifBelongPlace === 'Y' ? retreatProvince : '',
        city: currentGoods?.ifBelongPlace === 'Y' ? retreatCity : '',
      });
      if (res.code === 200) {
        let list = cardPool[goodsId + numberKeyword + retreatProvince + retreatCity] ?? [];
        setCardPool(
          Object.assign({}, cardPool, {
            [goodsId + numberKeyword + retreatProvince + retreatCity]: [...list, ...res.data],
          })
        );
        if (type === 'next') {
          let _p = cardPoolPage;
          setCardPoolPage(_p + 1);
        }
      } else {
        setCardPoolPage(0);
      }
    } catch (e) {
      showToast('获取号码池失败');
    }
  }

  // 加载商品列表
  async function loadGoodsList(id) {
    let list = dataMap[id || suffixCode] || [];
    if (list.length) {
      setGoodsList(list);
      if (list.length > 0) {
        setCurrentGoods(list[0]);
      }
    } else {
      try {
        let res = await getGoodsData(id || suffixCode);
        if (res.code === 200) {
          let _list = res.data || [];
          setGoodsList(_list);
          dataMap[id || suffixCode] = _list;
          if (_list.length > 0) {
            setCurrentGoods(_list[0]);
            startCountdownTimer(_list[0]);
          }
        }
      } catch (e) {}
    }
  }
  // 切换商品
  function handleSelectGoods(goods, index) {
    setSelect(index);
    setCardPoolPage(0);
    setCurrentGoods(goods);
    startCountdownTimer(goods);
  }

  // 开始获取验证码的倒计时
  function startCaptchaTimer() {
    if (captchaTimer.current.timer) {
      clearInterval(captchaTimer.current.timer);
    }
    captchaTimer.current.time = CAPTCHA_TIME;
    captchaTimer.current.timer = setInterval(() => {
      captchaTimer.current.time--;
      setCaptchaTime(captchaTimer.current.time);
      if (captchaTimer.current.time <= 0) {
        clearInterval(captchaTimer.current.timer);
        captchaTimer.current.timer = null;
      }
    }, 1000);
  }

  // 开始倒计时
  function startCountdownTimer(t) {
    if ((t ?? currentGoods)?.showCountDown === 'N') return;
    if (countdownTimer.current.timer) {
      clearInterval(countdownTimer.current.timer);
    }
    // 获取在后端设置的秒数
    const countDownSecond = (t ?? currentGoods).countDownSecond;

    // (当前时间 - 创建时间) / 秒数取余
    const specificDate = dayjs((t ?? currentGoods).createTime); // 创建时间
    const currentDate = dayjs(); // 当前日期
    const differenceInMilliseconds = currentDate.diff(specificDate); // 毫秒
    const differenceInSeconds = differenceInMilliseconds / 1000; // 毫秒转秒
    // const number = COUNTDOWN_NUM - (Math.floor(differenceInSeconds) % COUNTDOWN_NUM);
    let num = countDownSecond ? countDownSecond : COUNTDOWN_NUM;
    const number = num - (Math.floor(differenceInSeconds) % num);
    countdownTimer.current.time = number;
    countdownTimer.current.timer = setInterval(() => {
      countdownTimer.current.time--;
      setCountdownTime(countdownTimer.current.time);
      if (countdownTimer.current.time <= 0) {
        startCountdownTimer();
      }
    }, 1000);
  }

  // 发送验证码
  async function handleSendCaptcha() {
    if (captchaTime > 0) return;
    if (!tel || /^1[0-9]{10}$/.test(tel) !== true) {
      return showToast('请输入正确的手机号');
    }
    try {
      let res = await getSMSCode({
        pageId: currentGoods.id,
        cardName: name,
        cardNo: idCard,
        receiverName: name,
        receiverPhone: tel,
        province,
        city,
        county: area,
        receiverAddress: addressDetail,
      });
      if (res.code === 200) {
        setCaptchaKey(res.data.key);
        startCaptchaTimer();
        if (!showAll) {
          setShowAll(true);
        }
      }
      showToast(res.msg);
    } catch (e) {
      showToast(e.message);
    }
  }

  // 地址选择
  function handleAddressChange([p, c, a]) {
    setProvince(p);
    setCity(c);
    setArea(a);
  }
  // 归属地地址选择
  function handleRetreatChange([p, c]) {
    setRetreatProvince(p);
    setRetreatCity(c);
  }

  // 确认订单
  async function handleConfirm() {
    let errMsg;
    if (!name) {
      errMsg = '请输入姓名';
    } else if (!tel || /^1[0-9]{10}$/.test(tel) !== true) {
      errMsg = '请输入正确的手机号';
    } else if (!captcha) {
      errMsg = '请输入验证码';
    } else if (!showAll) {
      errMsg = '请发送验证码';
    } else if (!province || !city || !area) {
      errMsg = '请选择城市';
    } else if (!addressDetail) {
      errMsg = '请输入详细地址';
    } else if (!checkProtocol) {
      errMsg = '请阅读并同意协议';
    } else if (needIdCard) {
      if (!idCard) {
        errMsg = '请输入身份证号';
      } else if (idCard.trim().length !== 18) {
        errMsg = '请输入正确的身份证号';
      }
    }
    if (errMsg) {
      return showToast(errMsg);
    }

    try {
      var res = await commitOrder({
        pageId: currentGoods.id,
        cardName: name,
        cardNo: idCard,
        receiverName: name,
        receiverPhone: tel,
        province,
        city,
        county: area,
        receiverAddress: addressDetail,
        smsKey: captchaKey,
        smsCode: captcha,
        selectedNum: selectCardNum?.number ?? '',
        selectedNumCheckCode: selectCardNum?.checkCode ?? '',
        apiParam: parseLocationParams(window.location.search),
      });
      if (res.code === 200) {
        await showConfirm(res.msg || '提交成功');
        if (currentGoods?.returnId) {
          if (res.data?.ignoreCallback !== 'Y') {
            window._baq.track('form', { assets_id: currentGoods?.returnId });
          }
        }
        setName('');
        setTel('');
        setCaptcha('');
        setIdCard('');
        setProvince(null);
        setCity(null);
        setArea(null);
        setAddressDetail('');
        setCheckProtocol(false);
        if (currentGoods.pushType !== 'iot' && currentGoods.selectedNumStatus === 'Y') {
          setStep('1');
        } else {
          setStep('2');
        }
        setSelectCardNum(undefined);
        setSearchValue('all');
        setSearchValueInput('');
        setCardPoolPage(0);
      } else {
        showToast(res.msg);
      }
    } catch (e) {
      showToast(e.message);
    }
  }

  function createCardSortNum(num, match) {
    let _list = [];
    if (match) {
      let list = num.split(match[0]);
      _list.push(list[0]);
      for (let i = 1; i < list.length; i++) {
        _list.push(<span className="red">{match[0]}</span>);
        _list.push(<span>{list[i]}</span>);
      }
    } else {
      _list = [num];
    }
    return _list;
  }
  // 换一批按钮
  function handleNextPage() {
    let cardPoolLen = cardPool[currentGoods.id + searchValue + retreatProvince + retreatCity].length / 10;
    console.log('当前页数', cardPoolPage, '总页数', cardPoolLen);
    if (cardPoolPage === cardPoolLen - 1) {
      loadCardPool(currentGoods.id, 'next', searchValue);
    } else {
      let _p = cardPoolPage + 1;
      let length = (cardPool[currentGoods.id + searchValue + retreatProvince + retreatCity] ?? []).length;
      if (_p * 10 >= length) {
        _p = 0;
      }
      setCardPoolPage(_p);
    }
  }

  function createCardNum(num, exp) {
    let showList = [];
    if (exp) {
      exp.lastIndex = 0;
      let match = exp.exec(num);
      // console.log('match', match);
      showList = createCardSortNum(num, match);
    } else {
      showList = [num];
    }
    return (
      <div className="card">
        {showList.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
    );
  }
  // 正则排序
  function sortExpList() {
    let expList = [
      // AAA
      /(\d)\1{1,}/g,
      // ABAB
      /(\d)(\d)\1\2{1,}/g,
      // AB  ABC
      /(?:0(?=1)|1(?=2)|2(?=3)|3(?=4)|4(?=5)|5(?=6)|6(?=7)|7(?=8)|8(?=9)){1,}\d/g,
      // 爱情号
      /((52)|(520)|(521)|(13)|(14)|(1314)|(8)|(6)|(21)|(20)){1}/g,
      // AABB
      // /((\d)\1(\d)\2){2,}/g,
      /(\d)\1((?!\1)\d)\2/g,
      // no4
      /^[123567890]{11}$/g,
      // end8
      /[8]{1,}$/g,
    ];
    if (searchValue === 'all') {
      expList.unshift(expList.splice(1, 1)[0]);
    } else if (searchValue === 'AAA') {
    } else if (searchValue === 'ABAB') {
      expList.unshift(expList.splice(1, 1)[0]);
    } else if (searchValue === 'ABCD') {
      expList.unshift(expList.splice(2, 1)[0]);
    } else if (searchValue === '521') {
      expList.unshift(expList.splice(3, 1)[0]);
    } else if (searchValue === 'AABB') {
      expList.unshift(expList.splice(4, 1)[0]);
    } else if (searchValue === 'no4') {
      expList.unshift(expList.splice(5, 1)[0]);
    } else if (searchValue === 'end8') {
      expList.unshift(expList.splice(6, 1)[0]);
    } else {
      expList.unshift(new RegExp(searchValue, 'g'));
    }
    return expList;
  }

  function sortCardPool(list) {
    let expList = sortExpList();
    // if (exp) {
    let other = [...list];
    let _list = [];
    for (let i = 0; i < expList.length; i++) {
      let _other = [];
      other.forEach((j) => {
        expList[i].lastIndex = 0;
        let metch = expList[i].exec(j.number);
        if (metch) {
          j.exp = expList[i];
          _list.push(j);
          // other.splice(index, 1);
        } else {
          _other.push(j);
        }
      });
      other = _other;
    }

    _list = [..._list, ...other];

    return _list;
  }
  // 获取挽留数据
  async function getRetentionListFn(code) {
    if (retentionData.length > 0) return;
    try {
      var res = await getRetentionList(code);
      if (res.code === 200) {
        setRetentionData(res.data);
      } else {
        setRetentionData([]);
      }
    } catch {
      setRetentionData([]);
    }
  }

  // 跳转客服链接
  function goServerLink(url) {
    window.location.href = url;
  }
  if (!currentGoods) {
    return <div />;
  }

  let _imgList = currentGoods?.linkImgArray.split(';');
  let imgOne = _imgList?.shift();

  let currCardPool = cardPool[currentGoods.id + searchValue + retreatProvince + retreatCity] ?? [];
  currCardPool = sortCardPool(currCardPool);
  if (currCardPool.length > 0) {
    currCardPool = currCardPool.slice(cardPoolPage * 10, cardPoolPage * 10 + 10);
  }

  // 跳转路由
  function goHome() {
    setRetentionDialogShow(false);
    navigate(`/?id=${showData.pushCode}`);
  }

  function handlePopstate() {
    let params = window.location.href.split('id=')[1];
    let index = retentionData.findIndex((item) => item.pushCode === params);

    index++;
    if (index >= retentionData.length) {
      index = 0;
    }
    let showData = retentionData[index];
    setShowData(showData);
    setPushHistoryFlag(false);
    window.removeEventListener('popstate', handlePopstate);
    setRetentionDialogShow(true);
  }

  // 将时长(秒)转化为时分秒的形式
  function getTime(value) {
    let secondTime = parseInt(value); // 秒
    let minuteTime = 0; // 分
    let hourTime = 0; // 时
    if (secondTime > 60) {
      minuteTime = parseInt(secondTime / 60);
      secondTime = parseInt(secondTime % 60);
      if (minuteTime > 60) {
        hourTime = parseInt(minuteTime / 60);
        minuteTime = parseInt(minuteTime % 60);
      }
    }
    //若秒数是个位数，前面用0补齐
    secondTime = secondTime < 10 ? '0' + secondTime : secondTime;
    var result = '' + secondTime + '';
    if (minuteTime > 0) {
      minuteTime = minuteTime < 10 ? '0' + minuteTime : minuteTime;
      result = '' + minuteTime + ':' + result;
    } else {
      result = '' + '00' + ':' + result;
    }
    if (hourTime > 0) {
      hourTime = hourTime < 10 ? '0' + hourTime : hourTime;
      result = '' + hourTime + ':' + result;
    } else {
      result = '' + '00' + ':' + result;
    }
    return { hourTime: hourTime, minuteTime: minuteTime, secondTime: secondTime };
  }
  // 时分秒
  let countdownData = getTime(countdownTime);
  function SampleNextArrow() {
    return <div className="w-10 h-10" />;
  }
  function SamplePrevArrow() {
    return <div className="w-10 h-10" />;
  }
  function handleChangeVal() {
    setSearchValue(searchValueInput);
    loadCardPool(currentGoods?.id, '', searchValueInput);
  }
  // 订单记录-轮播图参数
  const setting = {
    speed: 1000,
    dots: false,
    infinite: true,
    autoplay: true,
    vertical: true,
    prevArrow: <SampleNextArrow />,
    nextArrow: <SamplePrevArrow />,
  };

  return (
    <div
      className={`index-page ${retentionDialogShow ? 'overflow-hidden' : ''}`}
      onTouchStart={() => {
        if (retentionData.length > 0 && !pushHistoryFlag) {
          setPushHistoryFlag(true);
          window.history.pushState({ page: 1 }, '', window.location.href);
          window.addEventListener('popstate', handlePopstate);
        }
      }}
    >
      <div className="search-order">
        {/* 客服链接按钮 */}
        {!!currentGoods.serverUrl && (
          <img
            className="serach-img"
            onClick={() => goServerLink(currentGoods.serverUrl)}
            src={currentGoods?.serverLogo ? currentGoods?.serverLogo : '/pic/icon_server.png'}
            alt=""
          />
        )}
        {/* 查询订单按钮 */}
        <img
          className="serach-img"
          onClick={() => navigate(`/searchorder?code=${currentGoods.id}`)}
          src={currentGoods?.queryLogo ? currentGoods?.queryLogo : '/pic/icon_search_order.png'}
          alt=""
        />
      </div>
      <img className="top-pic" src={imgOne} alt="" />
      {goodsList.length >= 2 ? (
        <div
          className="goods-list"
          style={{
            backgroundImage: `url('${currentGoods.bgImg}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          {goodsList.map((g, idx) => (
            <div
              key={g.pageName + idx}
              className={`goods-item ${idx == select ? 'active' : ''}`}
              onClick={() => handleSelectGoods(g, idx)}
            >
              {g.pageName.split(' ').map((i, idx) => (
                <div className="text" key={idx}>
                  {i}
                </div>
              ))}
              <img className="checked" src="/pic/icon_goods_check.png" alt="" />
            </div>
          ))}
        </div>
      ) : null}
      {step === '1' ? (
        <div
          className="select-card-block"
          style={{
            backgroundImage: `url('${currentGoods.bgImg}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className="countdown-goods">
            {/* 倒计时 */}
            {currentGoods.showCountDown === 'Y' && (
              <div className="countdown-box">
                <Countdown currentGoods={currentGoods} />
                <MilliSecond />
              </div>
            )}
            {/* 订单记录-轮播图 */}
            {currentGoods.showOrderPhone === 'Y' && (
              <Slider className="order-slider" {...setting}>
                {dataList.map((_, index) => (
                  <RandomNum key={index} />
                ))}
              </Slider>
            )}
          </div>
          <div className="search-block">
            {/* 选择归属地 */}
            {currentGoods.ifBelongPlace === 'Y' && (
              <div className="retreat-block">
                <span>
                  <img src="/pic/icon-position.png" alt="" />
                  归属地：
                </span>
                <div className="retreat-list">
                  <CityRetreat onChange={handleRetreatChange} select={!!retreatProvince} p={ipProvince} c={ipCity} />
                </div>
                <div className="select-tips">
                  请选择
                  <img src="/pic/icon-select.png" alt="" />
                </div>
              </div>
            )}
            <div className="input-block">
              <input className="input" type="text" onChange={(e) => setSearchValueInput(e.target.value)} />
              <div
                className="search-btn"
                // onClick={() => (searchValueInput ? setSearchValue(searchValueInput) : setSearchValue('all'))}
                onClick={() => {
                  searchValueInput ? handleChangeVal() : setSearchValue('all');
                }}
              >
                搜索
              </div>
            </div>
            <div className="search-values">
              {searchValues.map((i) => (
                <div
                  className={`search-item ${searchValue === i.value ? 'active' : ''}`}
                  key={i.value}
                  onClick={() => {
                    setSearchValue(i.value);
                    setCardPoolPage(0);
                    loadCardPool(currentGoods.id, '', i.value);
                  }}
                >
                  {i.label}
                </div>
              ))}
            </div>
          </div>
          <div className="card-pool-block">
            {currCardPool.map((i, idx) => (
              <div
                key={idx}
                className="card-pool-item"
                onClick={() => {
                  setSelectCardNum(i);
                  setStep(2);
                }}
              >
                {createCardNum(i.number, i.exp)}
                <div className="price-block">
                  <div className="price">￥200</div>
                  <div className="red">限时免费</div>
                </div>
              </div>
            ))}
          </div>
          {(cardPool[currentGoods.id + searchValue + retreatProvince + retreatCity]?.length ?? 0) >= 11 && (
            <div className="next-btn" onClick={handleNextPage}>
              换一批
            </div>
          )}
        </div>
      ) : (
        <div
          className="form-block"
          style={{
            backgroundImage: `url('${currentGoods.bgImg}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className="countdown-goods">
            {/* 倒计时 */}
            {currentGoods.showCountDown === 'Y' && (
              <div className="countdown-box">
                <Countdown currentGoods={currentGoods} />
                <MilliSecond />
              </div>
            )}
            {/* 订单记录-轮播图 */}
            {currentGoods.showOrderPhone === 'Y' && (
              <div className="order-slider">
                <Slider {...setting} className="order-list">
                  {dataList.map((_, index) => (
                    <RandomNum key={index} />
                  ))}
                </Slider>
              </div>
            )}
          </div>
          {currentGoods.pushType !== 'iot' && currentGoods.selectedNumStatus === 'Y' && (
            <div className="select-card-block">
              <div>已选{selectCardNum?.number}</div>
              <div className="reselect-btn" onClick={() => setStep('1')}>
                重新选号
              </div>
            </div>
          )}
          <div className="prop-line tips-error">
            温馨提示：为了物流可以正常派送，请您填写详细的收货地址及正确的联系方式，感谢您的配合！
          </div>
          <div className="prop-line">
            <div className="label">
              <span className="star">*</span>客户姓名
            </div>
            <input
              className="prop-input"
              placeholder={
                currentGoods.pushType === 'iot' ? '请输入办理人姓名(已加密)' : '与身份证上的姓名一致(已加密)'
              }
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
            />
          </div>
          <div className="prop-line">
            <div className="label">
              <span className="star">*</span>联系电话
            </div>
            <input
              className="prop-input"
              placeholder="收货手机号码(已加密)"
              value={tel}
              onChange={(e) => setTel(e.currentTarget.value)}
            />
          </div>
          <div className="prop-line">
            <div className="label">
              <span className="star">*</span>验证码
            </div>
            <input
              className="prop-input"
              placeholder="请输入验证码"
              value={captcha}
              onChange={(e) => setCaptcha(e.currentTarget.value)}
            />
            <div className={`btn-captcha ${captchaTime > 0 ? 'disabled' : ''}`} onClick={handleSendCaptcha}>
              {captchaTime > 0 ? captchaTime + 's' : '获取验证码'}
            </div>
          </div>
          <div className={`prop-line ${needIdCard ? '' : 'display-none'}`}>
            <div className="label">
              <span className="star">*</span>身份证号
            </div>
            <input
              className="prop-input"
              placeholder="(已加密，仅用于本次业务)"
              value={idCard}
              onChange={(e) => setIdCard(e.currentTarget.value)}
            />
          </div>
          <div className="prop-line">
            <div className="label">
              <span className="star">*</span>城市选择
            </div>
            <MCityPicker onChange={handleAddressChange} select={!!province} />
          </div>
          <div className="prop-line">
            <div className="label">
              <span className="star">*</span>详细地址
            </div>
            <input
              className="prop-input"
              placeholder="街道/镇+村庄/小区/写字楼+门牌号"
              value={addressDetail}
              onChange={(e) => setAddressDetail(e.currentTarget.value)}
            />
          </div>
          <div className="protocol-block">
            <MCheckbox className="checkbox" value={checkProtocol} onChange={() => setCheckProtocol(!checkProtocol)} />
            <span onClick={() => setCheckProtocol(!checkProtocol)}>我已阅读并同意</span>
            <span className="protocol" onClick={() => setShowProtocol('user')}>
              《个人信息收集证明》
            </span>
            和
            <span className="protocol" onClick={() => setShowProtocol('inter')}>
              《入网许可协议》
            </span>
            <span className="protocol" onClick={() => setShowProtocol('real')}>
              《实名登记说明》
            </span>
            <span className="protocol" onClick={() => setShowProtocol('protect')}>
              《个人信息保护政策》
            </span>
            {currentGoods.pushType === 'sim' && (
              <span className="protocol" onClick={() => setResellDialogShow(true)}>
                《转卖或转借电话卡风险告知书》
              </span>
            )}
          </div>
          <div className="btn-confirm" onClick={handleConfirm}>
            立即领取(包邮)
          </div>
        </div>
      )}
      {_imgList.map((i) => (
        <img key={i} alt="" className="top-pic" mode="widthFix" src={i} />
      ))}
      {currentGoods.thumbnailImg && !zhedieImg ? (
        <img alt="" className="top-pic" mode="widthFix" src={currentGoods.thumbnailImg} />
      ) : null}
      {currentGoods.thumbnailImg && (
        <div
          className="zhedie"
          style={{
            backgroundImage: `url('${currentGoods.bgImg}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
          onClick={() => setZhedieImg(!zhedieImg)}
        >
          {!zhedieImg ? '点击收起' : '点击展开'}
        </div>
      )}
      {step !== '1' && (
        <div className={`scroll-back ${!showToTop ? 'd-none' : ''} pb-40 pl-20 pr-20`}>
          <div className="btn-confirm" onClick={() => window.scrollTo(0, 0)}>
            立即领取(包邮)
          </div>
        </div>
      )}

      {showProtocol ? <ProtocolDialog type={showProtocol} onClose={() => setShowProtocol('')} /> : null}
      {resellDialogShow && <ResellDialog onClose={() => setResellDialogShow(false)} />}
      {/* 挽留弹窗 */}
      <RetentionDialog
        retentionDialogShow={retentionDialogShow}
        showData={showData}
        onClose={() => goHome()}
      ></RetentionDialog>
    </div>
  );
}

function MilliSecond() {
  let timerRef = useRef();
  const [millSecond, setMillSecond] = useState(0);
  useEffect(() => {
    timerRef.current = setInterval(() => {
      setMillSecond(1000 - dayjs().millisecond());
    }, 10);
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);
  // 补0
  let fillMilliSecond = millSecond + '';
  if (fillMilliSecond.length === 2) {
    fillMilliSecond = '0' + millSecond;
  } else if (fillMilliSecond.length === 1) {
    fillMilliSecond = '00' + millSecond;
  }
  return <span>{fillMilliSecond}</span>;
}

function RandomNum() {
  // 生成随机手机号
  const [phone, setPhone] = useState();
  // 生成订单时间随机数
  const [randomNum, setRandomNum] = useState(0);
  let phoneNumber = '';
  useEffect(() => {
    let num = Math.floor(Math.random() * 8) + 1;
    setRandomNum(num);
    phoneNumber += 1;
    for (let i = 0; i < 10; i++) {
      phoneNumber += Math.floor(Math.random() * 6) + 3; // 生成随机数字并添加到手机号码中
    }
    var phoneNumber1 = phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
    setPhone(phoneNumber1);
  }, []);
  return (
    <div className="order-li">
      {phone}已抢订{randomNum}分钟前
    </div>
  );
}
